/* default setting is for mobile, the fontSize and padding、margin
will changed for bigger screen */

/* base */
div.partner-trans-bg1 {
  background-image: url("/img/common/bg_trans_03.png");
  background-repeat: no-repeat;
  background-position: 0% 90%;
  background-size: 70%;
}

div.partner-part1 {
  padding-left: 1rem;
  padding-top: 1rem;
}
div.partner-part2 {
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 4rem;
}

@media (min-width: 768px) {
  div.partner-part1 {
    padding-top: 2rem;
  }
  div.partner-part2 {
    padding-top: 6rem;
  }
}
@media (min-width: 1200px) {
  div.partner-part1 {
    padding-top: 4rem;
  }
  div.partner-part2 {
    padding-top: 8rem;
  }
}

/* part1 */
p.partner-part1-primary {
  margin: 0px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

@media (min-width: 768px) {
  p.partner-part1-primary {
    font-size: 1.4rem;
  }
}
@media (min-width: 1200px) {
  p.partner-part1-primary {
    font-size: 2rem;
  }
}

/* part2 */
div.partner-part2-div {
  margin-bottom: 8rem;
}
p.partner-part2-content {
  text-align: justify;
  font-size: 1rem;
  color: #444444;
  margin-top: 0rem;
  margin-bottom: 2.5rem;
}
img.partner-part2-logo {
  padding-bottom: 2rem;
  padding-right: 2rem;
}

@media (min-width: 768px) {
  div.partner-part2-div {
    margin-bottom: 10rem;
  }
  p.partner-part2-content {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
  img.partner-part2-logo {
    padding-bottom: 1rem;
    padding-right: 3rem;
  }
}

@media (min-width: 1200px) {
  div.partner-part2-div {
    margin-bottom: 15rem;
  }
  p.partner-part2-content {
    font-size: 1.1rem;
    line-height: 1.8rem;
  }
  img.partner-part2-logo {
    padding-bottom: 1rem;
    padding-right: 6rem;
  }
}
