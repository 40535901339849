/* default setting is for mobile, the fontSize and padding、margin
will changed for bigger screen */

/* base */
div.footer-for-apply-bg-img {
  background-image: url("/img/footer_for_apply/footer_for_apply_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding-top: 0rem;
  padding-bottom: 1rem;
}
p.footer-for-apply-primary {
  padding-top: 1rem;
  margin-bottom: 0.3rem;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}
p.footer-for-apply-secondary {
  padding-top: 0.1rem;
  padding-bottom: 0.5rem;
  font-size: 0.8rem;
  font-weight: lighter;
  color: white;
}
@media (min-width: 768px) {
  div.footer-for-apply-bg-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 1rem;
    padding-bottom: 2rem;
  }
  p.footer-for-apply-primary {
    font-size: 1.8em;
    padding-top: 1rem;
    margin-bottom: 0.3rem;
  }
  p.footer-for-apply-secondary {
    font-size: 1.3rem;
    padding-bottom: 2rem;
  }
  button.footer-for-apply-button {
    width: 12rem;
  }
}
@media (min-width: 1200px) {
  div.footer-for-apply-bg-img {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 4rem;
    padding-bottom: 0rem;
  }
  p.footer-for-apply-primary {
    font-size: 2.2em;
    padding-top: 0rem;
    margin-bottom: 0.7rem;
  }
  p.footer-for-apply-secondary {
    font-size: 1.4rem;
    padding-bottom: 3rem;
  }
  button.footer-for-apply-button {
    width: 13rem;
    margin-bottom: 5rem;
  }
}
