/* default setting is for mobile, the fontSize and padding、margin
will changed for bigger screen */

/* base */

div.privacy-policy {
  position: relative;
  padding-top: 0rem;
}
div.privacy-policy-left {
  -ms-flex: 0 0 25rem;
  flex: 0 0 25rem;
  height: 45rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  margin-top: 10rem;
}
div.privacy-policy-right {
  margin-top: 3rem;
  padding-bottom: 4rem;
}
p.privacy-policy-right-title {
  margin-top: 0rem;
  margin-bottom: 1rem;
  text-align: center;
  font-size: 1.2rem;
}
p.privacy-policy-right-primary {
  font-size: 1rem;
  font-weight: bold;
  padding-bottom: 0.5rem;
}
p.privacy-policy-right-content {
  font-size: 0.9rem;
}
div.privacy-policy-right-space {
  height: 2rem;
}

div.col-8 div {
}

@media (min-width: 768px) {
  p.privacy-policy-right-title {
    margin-top: 3rem;
    margin-bottom: 0rem;
    text-align: center;
    font-size: 1.6rem;
  }
  p.privacy-policy-right-primary {
    font-size: 1.1rem;
    font-weight: bold;
    padding-bottom: 0.5rem;
  }
  p.privacy-policy-right-content {
    font-size: 1rem;
  }
  div.privacy-policy-right-space {
    height: 6rem;
  }
}
