/* default setting is for mobile, the fontSize and padding、margin
will changed for bigger screen */

/* base */

div.trail-apply-confirm-left {
  -ms-flex: 0 0 25rem;
  flex: 0 0 25rem;
  height: 100vh;
  min-height: 40rem;
  background-image: url("/img/trail_apply/trail_apply_left_bg.png");
  background-position: center;
  background-size: cover;
}
div.trail-apply-confirm-right {
  height: 80vh;
  min-height: 20rem;
}
div.trail-apply-confirm-right-part1 {
  padding-top: 1rem;
  padding-bottom: 0rem;
  padding-left: 1rem;
  margin-bottom: 3.5rem;
}
img.trail-apply-confirm-logo {
  padding-left: 0rem;
}

div.trail-apply-confirm-right-part2 {
  padding-bottom: 0rem;
  margin-bottom: 0rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
img.successful_icon {
  width: 5rem;
  height: 5rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
p.trail-apply-confirm-right-part2-primary {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-size: 1.5rem;
  margin-bottom: 3rem;
  font-weight: bolder;
}
p.trail-apply-confirm-right-part2-content {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

@media (min-width: 768px) {
  img.trail-apply-confirm-logo {
    padding-left: 1rem;
  }
}

@media (min-width: 1200px) {
  img.trail-apply-confirm-logo {
    padding-left: 2rem;
  }
}
