/* default setting is for mobile, the fontSize and padding、margin
will changed for bigger screen */

/* base */
div.price-trans-bg {
  background-image: url("/img/common/bg_trans_01.png");
  background-repeat: no-repeat;
  background-position: 100% 8%;
  background-size: 98%;
}

div.price-part1 {
  padding-top: 5rem;
  padding-bottom: 5rem;
  background-image: url("/img/price/price_banner.png");
}
div.price-part2 {
  padding-top: 3rem;
  padding-bottom: 3rem;
  align-items: center;
  text-align: center;
}
div.price-part3 {
  padding-top: 3rem;
  padding-bottom: 3rem;
  align-items: center;
  text-align: center;
}
@media (min-width: 768px) {
  div.price-part1 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
  div.price-part2 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
}

@media (min-width: 1200px) {
  div.price-part1 {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  div.price-part2 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }
}

/* part1 */
p.price-part1-primary {
  margin: 0px;
  font-size: 1.1rem;
  font-weight: normal;
  text-align: center;
  color: white;
}

@media (min-width: 768px) {
  p.price-part1-primary {
    font-size: 1.8rem;
  }
}

@media (min-width: 1200px) {
  p.price-part1-primary {
    font-size: 2.2rem;
  }
}

/* part2 */
div.price-part2-row {
  padding: 0.6rem;
}
div.price-part2-colume {
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  padding-bottom: 1.4rem;
}
