/* default setting is for mobile, the fontSize and padding、margin
will changed for bigger screen */

/* base */
nav.navbar {
}

img.navbar-logo {
  padding-right: 4rem;
  padding-left: 0.5rem;
}
a.dropdown-item {
  padding: 1rem;
}
div.nav-item {
  margin-right: 1.5rem;
}
div.navlink {
  margin-right: 1.5rem;
}
div.dropdown.open.active > a:hover,
div.dropdown.open > a {
  color: green;
  background-color: #b91773;
  border-color: blue;
}
