/* default setting is for mobile, the fontSize and padding、margin
will changed for bigger screen */

/* base */

div.trail-apply-left {
  -ms-flex: 0 0 25rem;
  flex: 0 0 25rem;
  height: 100vh;
  min-height: 40rem;
  background-image: url("/img/trail_apply/trail_apply_left_bg.png");
  background-position: center;
  background-size: cover;
}
div.trail-apply-right {
  height: 80vh;
  min-height: 20rem;
}
div.trail-apply-right-part1 {
  padding-top: 1rem;
  padding-bottom: 0rem;
  padding-left: 1rem;
  margin-bottom: 3.5rem;
}
img.logo {
  padding-left: 0rem;
}
div.trail-apply-right-part2 {
  padding-bottom: 0rem;
  margin-bottom: 0rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
p.trail-apply-right-part2-primary {
  width: 100%;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: bolder;
}
p.trail-apply-right-part2-content {
  font-size: 0.9rem;
  margin-bottom: 2rem;
}

form.trail-apply-right-part2-form {
  width: 100%;
}

::placeholder {
  color: #aaaaaa !important;
  opacity: 1; /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #aaaaaa !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #aaaaaa !important;
}

button.trail-apply-right-part2-submit {
  margin-top: 2rem;
  width: 100%;
  height: 2.5rem;
  border-radius: 3rem;
  text-align: center;
}

label.trail-apply-right-part2-label {
  font-size: 0.8rem;
  color: #444444;
}

@media (min-width: 768px) {
  img.logo {
    padding-left: 1rem;
  }
  form.trail-apply-right-part2-form {
    width: 100%;
    min-width: 20rem;
  }
  p.trail-apply-right-part2-primary {
    min-width: 20rem;
  }
  p.trail-apply-right-part2-content {
    min-width: 20rem;
  }
}

@media (min-width: 1200px) {
  img.trail-apply-logo {
    padding-left: 2rem;
  }
  form.trail-apply-right-part2-form {
    width: 100%;
  }
}
