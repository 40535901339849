/* default setting is for mobile, the fontSize and padding、margin
will changed for bigger screen */

/* base */
div.home-part6-trans-bg {
  background-image: url("/img/common/bg_trans_02.png");
  background-repeat: no-repeat;
  background-position: 0% 30%;
  background-size: 85%;
}
div.homepage-part1 {
  padding-left: 1rem;
  padding-top: 3rem;
  padding-bottom: 4rem;
}
div.homepage-part2 {
  background-color: white;
  padding-top: 4rem;
  padding-bottom: 3rem;
}
div.homepage-part3 {
  background-color: white;
  padding-left: 1rem;
  padding-top: 2rem;
  padding-bottom: 1rem;
}
div.homepage-part4 {
  background-image: url("/img/home/home_cooperation_bg.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 4rem;
  padding-bottom: 5rem;
}
div.homepage-part5 {
  background-color: white;
  padding-top: 4rem;
  padding-bottom: 0.1rem;
}
div.homepage-part6 {
  padding-top: 0.1rem;
  padding-bottom: 4rem;
}

@media (min-width: 768px) {
  div.home-part6-trans-bg {
    background-position: 0% 0%;
    background-size: 40%;
  }
  div.homepage-part1 {
    padding-top: 3rem;
    padding-bottom: 6rem;
  }
  div.homepage-part2 {
    padding-bottom: 2rem;
  }
  div.homepage-part3 {
    padding-top: 2rem;
  }
  div.homepage-part5 {
    padding-top: 5rem;
  }
  div.homepage-part6 {
    padding-top: 2rem;
    padding-bottom: 6rem;
  }
}
@media (min-width: 1200px) {
  div.home-part6-trans-bg {
    background-position: 0% 0%;
    background-size: 40%;
  }
  div.homepage-part1 {
    padding-top: 2rem;
    padding-bottom: 6rem;
  }
  div.homepage-part2 {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
  div.homepage-part3 {
    padding-left: 0rem;
    padding-top: 5rem;
  }
  div.homepage-part5 {
    padding-top: 8rem;
  }
  div.homepage-part6 {
    padding-top: 4rem;
    padding-bottom: 10rem;
  }
}

/* part1 */
p.homepage-part1-primary {
  padding-top: 1.2rem;
  margin-bottom: 0rem;
  font-size: 1.6rem;
  font-weight: bold;
}
p.homepage-part1-secondary {
  font-size: 1.9rem;
  font-weight: lighter;
}
p.homepage-part1-content {
  font-size: 1rem;
  color: #444444;
  margin-top: 1.5rem;
  margin-bottom: 3rem;
}
img.home-banner-bg {
  width: 80%;
  padding-top: 3rem;
  padding-bottom: 1rem;
}
button.homepage-part1-apply-button {
  margin-top: 0rem;
  border-radius: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}

@media (min-width: 768px) {
  p.homepage-part1-primary {
    padding-top: 3rem;
    font-size: 1.6rem;
  }
  p.homepage-part1-secondary {
    font-size: 1.9rem;
  }
  p.homepage-part1-content {
    font-size: 1rem;
  }
  img.home-banner-bg {
    width: 100%;
  }
  button.homepage-part1-apply-button {
    margin-top: 1rem;
    border-radius: 2.6rem;
    padding-left: 1.8rem;
    padding-right: 1.8rem;
    width: 10rem;
    height: 2.6rem;
    font-size: 1.2rem;
  }
}
@media (min-width: 1200px) {
  p.homepage-part1-primary {
    padding-top: 3rem;
    font-size: 2rem;
  }
  p.homepage-part1-secondary {
    font-size: 2.4rem;
  }
  p.homepage-part1-content {
    font-size: 1.2rem;
  }
  img.home-banner-bg {
    width: 100%;
  }
  button.homepage-part1-apply-button {
    margin-top: 1rem;
    border-radius: 3rem;
    padding-left: 2rem;
    padding-right: 2rem;
    width: 11rem;
    height: 3rem;
    font-size: 1.4rem;
  }
}

/* part2 */
p.homepage-part2-primary {
  margin: 0px;
  font-size: 1.1rem;
  font-weight: normal;
  color: #333333;
}
p.homepage-part2-secondary {
  padding-top: 0.5rem;
  font-size: 1.4rem;
  font-weight: lighter;
  color: #333333;
}

@media (min-width: 768px) {
  p.homepage-part2-primary {
    font-size: 1.5rem;
  }
  p.homepage-part2-secondary {
    font-size: 2rem;
  }
}
@media (min-width: 1200px) {
  p.homepage-part2-primary {
    font-size: 1.8rem;
  }
  p.homepage-part2-secondary {
    font-size: 2.4rem;
  }
}

/* part3 */
div.homepage-part3-div {
  margin-bottom: 4rem;
}
p.homepage-part3-primary {
  margin: 0px;
  margin-bottom: 1.5rem;
  font-size: 1.2rem;
  font-weight: bold;
}
p.homepage-part3-secondary {
  font-size: 1rem;
  margin-top: 0rem;
  margin-bottom: 0.4rem;
  color: #333333;
  font-weight: bold;
}
p.homepage-part3-content {
  font-size: 1rem;
  color: #555555;
  margin-top: 0rem;
  margin-bottom: 1.5rem;
}
img.homepage-part3-img {
  width: 90%;
}

@media (min-width: 768px) {
  div.homepage-part3-div {
    margin-bottom: 5rem;
  }
  p.homepage-part3-primary {
    font-size: 1.6rem;
  }
  p.homepage-part3-secondary {
    font-size: 1.2rem;
  }
  p.homepage-part3-content {
    font-size: 1.1rem;
  }
  img.homepage-part3-img {
    width: 100%;
  }
}
@media (min-width: 1200px) {
  div.homepage-part3-div {
    margin-bottom: 8rem;
  }
  p.homepage-part3-primary {
    font-size: 2rem;
    padding-bottom: 1rem;
  }
  p.homepage-part3-secondary {
    font-size: 1.2rem;
  }
  p.homepage-part3-content {
    font-size: 1.1rem;
  }
  img.homepage-part3-img {
    width: 100%;
  }
}

/* part4 */
/* p.homepage-part4-primary */
p.homepage-part4-primary {
  margin: 0px;
  font-size: 1.2rem;
  font-weight: bold;
  color: white;
}
p.homepage-part4-secondary {
  padding-top: 0.8rem;
  padding-bottom: 3rem;
  font-size: 1rem;
  font-weight: normal;
  color: white;
}
div.homepage-part4-img {
  padding-top: 1rem;
}
img.homepage-part4-img {
  height: 3rem;
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  p.homepage-part4-primary {
    font-size: 1.8rem;
  }
  p.homepage-part4-secondary {
    font-size: 1.2rem;
  }
}

@media (min-width: 1200px) {
  p.homepage-part4-primary {
    font-size: 1.8rem;
  }
  p.homepage-part4-secondary {
    font-size: 1.2rem;
  }
}

/* part5 */
/* p.homepage-part5-primary */
p.homepage-part5-primary {
  margin: 0px;
  margin-bottom: 5rem;
  font-size: 1.2rem;
  font-weight: bold;
}
p.homepage-part5-secondary {
  font-size: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: bold;
  color: #444444;
}
p.homepage-part5-content {
  font-size: 0.8rem;
  color: #777777;
  margin-top: 0rem;
  margin-bottom: 1.5rem;
}
div.homepage-part5-cell {
  padding-bottom: 3rem;
}
img.home-part5-img {
  width: 6rem;
}

@media (min-width: 768px) {
  p.homepage-part5-primary {
    font-size: 1.8rem;
  }
  p.homepage-part5-secondary {
    font-size: 1.3rem;
  }
  p.homepage-part5-content {
    font-size: 1rem;
  }
  div.homepage-part5-cell {
    padding-bottom: 4rem;
  }
  img.home-part5-img {
    width: 8rem;
  }
}

@media (min-width: 1200px) {
  p.homepage-part5-primary {
    font-size: 1.8rem;
  }
  p.homepage-part5-secondary {
    font-size: 1.3rem;
  }
  p.homepage-part5-content {
    font-size: 1rem;
  }
  div.homepage-part5-cell {
    padding-bottom: 6rem;
  }
  img.home-part5-img {
    width: 10rem;
  }
}

/* part6 */
/* p.homepage-part6-primary */
p.homepage-part6-primary {
  margin: 0px;
  margin-bottom: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
}
p.homepage-part6-secondary {
  font-size: 0.9rem;
  margin-top: 1.5rem;
  margin-bottom: 0.3rem;
  font-weight: normal;
}
div.homepage-part6-img {
  padding: 2rem;
}

@media (min-width: 768px) {
  p.homepage-part6-primary {
    font-size: 1.8rem;
    margin-bottom: 3rem;
  }
  p.homepage-part6-secondary {
    font-size: 1.3rem;
  }
}
@media (min-width: 1200px) {
  p.homepage-part6-primary {
    font-size: 1.8rem;
    margin-bottom: 6rem;
  }
  p.homepage-part6-secondary {
    font-size: 1.3rem;
  }
}

/* animation */
img.homepage-part3-img1 {
  /* animation-name: example; */
  /* animation-duration: 2s; */
}

@keyframes example {
  /* 0% {
    width: 50%;
  }
  100% {
    width: 100%;
  } */
}
