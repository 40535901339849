/* default setting is for mobile, the fontSize and padding、margin
will changed for bigger screen */
/* base */

div.contact-us-trans-bg {
  background-image: url("/img/common/bg_trans_04.png");
  background-repeat: no-repeat;
  background-position: 40% 0%;
  background-size: 100%;
}
div.contact-us {
  padding-top: 1.5rem;
  padding-bottom: 0rem;
}
div.contact-us-part1 {
  padding-top: 1rem;
  padding-bottom: 2rem;
}
div.contact-us-part2 {
  padding-bottom: 3rem;
}

@media (min-width: 768px) {
  div.contact-us-part2 {
    padding-bottom: 5rem;
  }
}
@media (min-width: 1200px) {
  div.contact-us-part2 {
    padding-bottom: 10rem;
  }
}

/* part1 */
p.contact-us-part1-primary {
  margin: 0px;
  font-size: 1.4rem;
  font-weight: bold;
  text-align: center;
}
@media (min-width: 768px) {
  p.contact-us-part1-primary {
    font-size: 1.8rem;
  }
}
@media (min-width: 1200px) {
  p.contact-us-part1-primary {
    font-size: 2rem;
  }
}

/* part2 */
div.contact-us-card {
  padding-top: 2rem;
  margin-bottom: 3rem;
  width: 16rem;
  height: 23rem;
  margin-left: auto;
  margin-right: auto;
}

div.contact-us-card-footer {
  border-width: 0;
  border-bottom-right-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
  padding-bottom: 2rem;
  background-color: white;
  height: 6rem;
}
p.contact-us-card-primary {
  font-size: 1.4rem;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
p.contact-us-card-content {
  padding-top: 0rem;
  padding-bottom: 1rem;
  font-size: 0.9rem;
}
img.contact-us-qrcode {
  margin-top: 1rem;
  height: 6rem;
  width: 6rem;
}
button.contact-us-button {
  margin-top: 3rem;
}
